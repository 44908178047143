.action-bar {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.25;
  transition: opacity 0.3s;
}

.action-bar:hover {
  opacity: 1;
}
